// import react
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import components
import { BeatLoader } from "react-spinners";
import Loading from "../../../components/Loading";
// import style components
import palette from "../../../constants/styles";
import * as Styled from "../../../styles/LandDetails.styles";
// import constants
import { LAND_TYPES } from "../../../constants/enums";
// import functions
import axios from "axios";
import { fetchLandData, fetchLandLike, fetchLandPolygonData, fetchLandPredictPriceData } from "../../../utils/api";
import { setLandDataLoading } from '../../../store/actions/globalValues';
// import icons
import { ReactComponent as RoadViewIcon } from "../../../assets/images/icons/road_view.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/icons/star_icon.svg";
import { ReactComponent as FilledStarIcon } from "../../../assets/images/icons/filled_star_icon.svg";


const LandDetails = ({ isMobile=false, setRegLandData, openLandReportModal }) => {
    // 전역 변수 관리
    const dispatch = useDispatch();
    const landAddress = useSelector(state => state.globalValues.landAddress);
    const isLoading = useSelector(state => state.globalValues.isLandDataLoading)

    //const landData = useSelector(state => state.globalValues.landData);
    const userLoginStatus = useSelector(state => state.userStatus.userLoginStatus);
    const currUser = useSelector(state => state.userStatus.currUser);
    //const selectLand = useSelector(state => state.globalValues.selectLand);
    
    const [roadviewOn, setRoadviewOn] = useState(false);
    const [landLike, setLandLike] = useState(false);
    const [landOwner, setLandOwner] = useState(false);

    // 토지 정보
    const [address, setAddress] = useState("");
    const [predPrice, setPredPrice] = useState(0);
    const [landPrice, setLandPrice] = useState(0);
    const [landArea, setLandArea] = useState(0.0);
    const [landClassification, setLandClassification] = useState("");
    const [landUseSitt, setLandUseSitt] = useState("");
    const [landZoning, setLandZoning] = useState("");
    const [roadSide, setRoadSide] = useState("");
    const [landForm, setLandForm] = useState("");
    const [landHeight, setLandHeight] = useState("");
    const [landUses, setLandUses] = useState("");
    const [lastPredictedDate, setLastPredictedDate] = useState("");
    const [landFeatureStdrYear, setLandFeatureStdrYear] = useState(0);
    const [dealChart, setDealChart] = useState([]);

    const [bidData, setBidData] = useState(null);
    const [bidCourtInCharge, setBidCourtInCharge] = useState("");
    const [bidCaseCd, setBidCaseCd] = useState("");
    const [bidAppraisalPrice, setBidAppraisalPrice] = useState(0);
    const [bidMinimumSalePrice, setBidMinimumSalePrice] = useState(0);
    const [bidDate, setBidDate] = useState("");
    const [bidCaseReception, setBidCaseReception] = useState("");
    const [bidStartDate, setBidStartDate] = useState("");
    const [bidDivRequestDeadline, setBidDivRequestDeadline] = useState("");
    const [bidBillableAmount, setBidBillableAmount] = useState(0);
    const [bidType, setBidType] = useState("");
    const [bidLandList, setBidLandList] = useState([]);

    const [landPropertyData, setLandPropertyData] = useState(null);
    const [landPropertyArea, setLandPropertyArea] = useState(0.0);
    const [landPropertyPrice, setLandPropertyPrice] = useState(0);
    const [landPropertySummary, setLandPropertySummary] = useState("");
    const [ownerNickname, setOwnerNickname] = useState("");

    const [totalLike, setTotalLike] = useState("0");
    const [userLike, setUserLike] = useState(false);

    useEffect(() => {
        if (!landAddress) { return; }
        if (landAddress.type === LAND_TYPES.CLUSTERER_LAND || landAddress.type === LAND_TYPES.REGION_LAND) { return; }
        dispatch(setLandDataLoading(true));
        //dispatch(setSideStatus("land-info"))
        fetchLandData({ pnu: landAddress.pnu })
        .then(response => {
            // 토지 정보 저장
            setAddress(response.data.data.addr);
            setPredPrice(response.data.data.land_info.predict_land_price);
            setLandPrice(response.data.data.land_info.official_land_price);
            setLandArea(response.data.data.land_info.land_area);
            setLandClassification(response.data.data.land_info.land_classification);
            setLandUseSitt(response.data.data.land_info.land_use_situation);
            setLandZoning(response.data.data.land_info.land_zoning);
            setRoadSide(response.data.data.land_info.road_side);
            setLandForm(response.data.data.land_info.land_form);
            setLandHeight(response.data.data.land_info.land_height);
            setLandUses(response.data.data.land_info.land_uses);
            setLastPredictedDate(response.data.data.last_predicted_date);
            setLandFeatureStdrYear(response.data.data.land_feature_stdr_year);
            console.log(response.data.data);

            setDealChart(response.data.data.land_trade_list);

            setBidData(response.data.data.bid_data);
            setLandPropertyData(response.data.data.land_property_data);
            setTotalLike(response.data.data.total_like);
            //setUserLike(landData["user_like"]);s
            
            // 경매 정보 저장
            if (response.data.data.bid_data !== null) {
                setBidAppraisalPrice(response.data.data.bid_data.case_info.appraisal_price);
                setBidBillableAmount(response.data.data.bid_data.case_info.billable_amount);
                setBidCaseCd(response.data.data.bid_data.case_cd);
                setBidCaseReception(response.data.data.bid_data.case_info.case_reception);
                setBidCourtInCharge(response.data.data.bid_data.case_info.court_in_charge);
                setBidDate(response.data.data.bid_data.case_info.bid_date);
                setBidDivRequestDeadline(response.data.data.bid_data.case_info.div_request_deadline);
                setBidLandList(response.data.data.bid_data.case_info.land_list);
                setBidMinimumSalePrice(response.data.data.bid_data.case_info.min_sale_price);
                setBidStartDate(response.data.data.bid_data.case_info.bid_start_date);
                setBidType(response.data.data.bid_data.case_info.bid_type);
            }
            console.log(response.data.data);
            // 매물 정보 저장
            setLandPropertyData(response.data.data.land_property_data);
            if (response.data.data.land_property_data !== null) {
                setLandPropertyArea(response.data.data.land_property_data.land_area);
                setLandPropertyPrice(response.data.data.land_property_data.land_price);
                setLandPropertySummary(response.data.data.land_property_data.land_summary);
                setOwnerNickname(response.data.data.land_property_data.nickname);
            }
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error("Request canceled", error.message);
            } else {
                console.log(error);
            }
        })
        .finally(() => {
            dispatch(setLandDataLoading(false));
            fetchLandPredictPriceData({ pnu: landAddress.pnu })
            .then(response => {
                console.log(response)
                setPredPrice(response.data.data.land_info.predict_land_price);
                setLastPredictedDate(response.data.data.last_predicted_date);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.error("Request canceled", error.message);
                } else {
                    console.log(error);
                }
            });
        });
    }, [landAddress]);

    useEffect(() => {
        console.log(isLoading)

        if (isLoading === false) {
            // 사이드 윈도우 지도 설정
            var iframe = document.getElementsByClassName("side-window-map")[0]
            setRoadviewOn(false);
            if(iframe !== undefined) {
                // 지적도 조회
                fetchLandPolygonData({ pnu: landAddress.pnu })
                .then(response => {
                    iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(response)), '*');
                    iframe.addEventListener("load", ev => {
                        document.getElementsByClassName("side-window-map")[0].contentWindow.postMessage(JSON.parse(JSON.stringify(response)), '*');
                    }); //로딩이 안끝나면 여기서 처리해준다.
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.error("Request canceled", error.message);
                    } else {
                        console.log(error);
                    }
                });
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (landAddress === null) { return; }
        var iframe = document.getElementsByClassName("side-window-map")[0]
        if (iframe !== undefined) {
            // 로드맵
            var returnValue = {
                "data": {
                    "position": {
                        'lat': landAddress.lat,
                        'lng': landAddress.lng
                    },
                    "isOpen": roadviewOn,
                    "result": "success"
                },
                "status": 200
            }
            document.getElementsByClassName("side-window-map")[0].contentWindow.postMessage(JSON.parse(JSON.stringify(returnValue)), '*');
        }
    }, [roadviewOn]);

    const transCaseCd = (caseCd) => {
        var returnStr = "";
        returnStr += caseCd.substr(0, 4);
        returnStr += "타경";
        returnStr += caseCd.substr(7).replace(/(^0+)/, "");
        return returnStr;
    }

    // 좋아요 버튼 핸들러
    const HandlerLikeButton = () => {
        if (!landAddress) { return; }
        if (!userLoginStatus) {
            window.alert("좋아요 기능을 이용하시려면 로그인 해 주세요.");
        } else {
            fetchLandLike({ email: currUser.email, pnu: landAddress.pnu })
            .then(response => {
                if (response.data.msg === "like") {
                    setLandLike(true);
                } else {
                    setLandLike(false);
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.error("Request canceled", error.message);
                } else {
                    console.log(error);
                }
            });
        }
    }

    // 소유주 등록 버튼 핸들러
    const HandlerRegisterButton = () => {
        if (!userLoginStatus) {
            window.alert("매물 등록 기능을 이용하시려면 로그인 해 주세요.");
        } else if (landPropertyData !== null) {
            window.alert("이미 등록되어 있는 땅입니다.");
        
        } else if (landPropertyData === null) {
            setRegLandData({
                ...landAddress,
                land_area: landArea,
                land_predict_price: Math.floor(predPrice * landArea),
            });
        } 
    }

    // 조건부 렌더링
    // PC 화면
    if (!isMobile) {
        if (landAddress === null) {
            return(
                <Styled.Container>
                    <Styled.Content>
                        <Styled.LandAddrTxt style={{marginTop: "340px"}}>지역을 선택해주세요.</Styled.LandAddrTxt>
                    </Styled.Content>
                </Styled.Container>
            );
        } else if (isLoading) {
            return (
                <Loading
                    loadingAddr={landAddress.address.address}
                    type="토지 정보"
                />
            )
        } else {
            return (
            <Styled.Container>
                <Styled.Content>
                    <Styled.Map className="side-window-map" src="https://csgpu.kku.ac.kr/~202120990/iframe2.html"/>
                    <Styled.RoadViewBtn 
                        style={roadviewOn ? {backgroundColor: palette.grayM, color: palette.whiteL} : {backgroundColor: palette.whiteL, color:palette.grayM}}
                        onClick={() => setRoadviewOn(!roadviewOn)}
                    >
                        <RoadViewIcon/>
                    </Styled.RoadViewBtn>
                    <Styled.LikeBtn 
                        style={landLike ? {} : {backgroundColor: palette.whiteL}} 
                        onClick={() => HandlerLikeButton()}
                    >
                        { landLike ? <FilledStarIcon/> : <StarIcon/> }
                    </Styled.LikeBtn>
                    <Styled.RegisterBtn 
                        onClick={() => HandlerRegisterButton()} 
                        style={landPropertyData === null ? {backgroundColor: palette.grayM, color: palette.whiteL} : {backgroundColor: palette.whiteL, color: palette.grayM}}
                    >
                        {landPropertyData === null ? "매물 등록" : ownerNickname + "님의 토지"}
                    </Styled.RegisterBtn>
                    <div style={{marginTop: "300px"}}></div>

                    <Styled.LikeCountTxt>{totalLike} 명이 이 토지를 좋아합니다.</Styled.LikeCountTxt>
                    
                    { bidData !== null && <Styled.BidCaseCdTxt>{bidCourtInCharge} {transCaseCd(bidCaseCd)}</Styled.BidCaseCdTxt> }
                    <Styled.LandAddrTxt>{address}</Styled.LandAddrTxt>
                    <Styled.DivLine/>
                    {predPrice !== null 
                        ? 
                        <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                            <Styled.LandMiniTxt>토지 예측가({lastPredictedDate} 기준)</Styled.LandMiniTxt>
                            <Styled.LandPriceTxt style={predPrice / landPrice > 1 ? {color: palette.redM} : {color: palette.blueM}}>{Math.floor(predPrice * landArea).toLocaleString('ko-KR')}원</Styled.LandPriceTxt>
                            <Styled.LandPricePerTxt style={predPrice / landPrice > 1 ? {color: palette.redM} : {color: palette.blueM}}>{Math.floor(predPrice).toLocaleString('ko-KR')}원/m²당</Styled.LandPricePerTxt>
                            <div>
                                <Styled.LandMiniTxt>공시지가의 </Styled.LandMiniTxt>
                                <Styled.LandMiniTxt style={predPrice / landPrice > 1 ? {color: palette.redM} : {color: palette.blueM}}>{parseInt(predPrice / landPrice * 100)}%</Styled.LandMiniTxt>
                            </div>
                            <Styled.ViewLandReportBtn onClick={() => openLandReportModal()} >AI 토지 분석서 확인하기</Styled.ViewLandReportBtn>
                        </div>
                        :
                        <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                            <Styled.LandMiniTxt>토지 예측가</Styled.LandMiniTxt>
                            <br/>
                            <BeatLoader color={palette.blueM}/>
                            <br/>
                            <Styled.LandMiniTxt>토지 가격을 예측하는 중입니다...</Styled.LandMiniTxt>
                            
                        </div>
                    }
                    <Styled.DivLine/>
                    { landPropertyData !== null && (
                        <div style={{width:"94%"}}>
                            <Styled.LandTitleTxt>매물 정보</Styled.LandTitleTxt><br/>
                            <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                                <Styled.LandPropertyAreaTxt>{landClassification} {landPropertyArea}m²</Styled.LandPropertyAreaTxt>
                                <Styled.LandPropertyPriceTxt>{Math.floor(landPropertyPrice).toLocaleString('ko-KR')}원</Styled.LandPropertyPriceTxt>
                                <Styled.LandPropertySummaryContainer>{landPropertySummary}</Styled.LandPropertySummaryContainer>
                            </div>
                            <Styled.DivLine/> 
                        </div>
                    )}

                    { bidData !== null && (
                        <div>
                            <Styled.LandTitleTxt>경매 정보</Styled.LandTitleTxt><br/>
                            <Styled.BidInfoDiv style={{marginTop: "20px"}}>
                                <div style={{display:"flex"}}>
                                    <Styled.BidPriceDiv>
                                        <Styled.BidInfoSubTitleTxt>감정가</Styled.BidInfoSubTitleTxt>
                                        <Styled.BidPriceTxt>{Math.floor(bidAppraisalPrice).toLocaleString('ko-KR')}</Styled.BidPriceTxt>
                                    </Styled.BidPriceDiv>
                                    <Styled.BidPriceDiv style={{border:"0"}}>
                                        <Styled.BidInfoSubTitleTxt>최저가</Styled.BidInfoSubTitleTxt>
                                        <Styled.BidPriceTxt style={{color:"#0067a3"}}>{Math.floor(bidMinimumSalePrice).toLocaleString('ko-KR')}</Styled.BidPriceTxt>
                                    </Styled.BidPriceDiv>
                                    
                                </div>
        
                                
                            </Styled.BidInfoDiv>
                            <Styled.BidInfoDiv>
                                <Styled.BidDateSubTitleTxt>매각기일</Styled.BidDateSubTitleTxt>
                                <Styled.BidDateTxt>{bidDate}</Styled.BidDateTxt>
                            </Styled.BidInfoDiv>
                            <Styled.BidInfoDiv>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>사건접수</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{bidCaseReception}</Styled.BidInfoTxt>
                                </div>
                                <Styled.TinyDivLine/>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>경매개시일</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{bidStartDate}</Styled.BidInfoTxt>
                                </div>
                                <Styled.TinyDivLine/>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>배당요구종기</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{bidDivRequestDeadline}</Styled.BidInfoTxt>
                                </div>
                                <Styled.TinyDivLine/>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>청구금액</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{Math.floor(bidBillableAmount).toLocaleString('ko-KR')}원</Styled.BidInfoTxt>
                                </div>
                            </Styled.BidInfoDiv>
                            <Styled.BidInfoDiv>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>입찰방법</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{bidType}</Styled.BidInfoTxt>
                                </div>
                                <Styled.TinyDivLine/>
                                <div style={{display:"flex"}}>
                                    <Styled.BidInfoSubTitleTxt>담당</Styled.BidInfoSubTitleTxt>
                                    <Styled.BidInfoTxt>{bidCourtInCharge}</Styled.BidInfoTxt>
                                </div>
                            </Styled.BidInfoDiv>
        
                            <Styled.BidSubTitleTxt>{transCaseCd(bidCaseCd)} 물건 내역</Styled.BidSubTitleTxt>
                            <Styled.BidObjDiv>
                                {bidLandList.map((land, index) => {
                                    return (
                                        address === land.addr.address ?
                                            <Styled.BidObjBtn style={{textDecoration:"none", color: "#343a40", cursor:"default"}}>{land.addr.address}</Styled.BidObjBtn>
                                        :   <Styled.BidObjBtn onClick={() => dispatch(setLandAddress({ type: LAND_TYPES.LAND_INFO, lat: land.lat, lng: land.lng, addr: land.addr, pnu: land.pnu }))}>{land.addr.address}</Styled.BidObjBtn>
                                    );
                                })}
                            </Styled.BidObjDiv>
                            <Styled.DivLine/>
                        </div>
                    )}

                    <Styled.LandTitleTxt>토지 기본 정보 (기준년도: {landFeatureStdrYear}년)</Styled.LandTitleTxt>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>지목</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{landClassification}</Styled.LandTxt>
                        </Styled.LandBox>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>이용상황</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{landUseSitt}</Styled.LandTxt>
                        </Styled.LandBox>
                    </div>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>용도지역</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{landZoning}</Styled.LandTxt>
                        </Styled.LandBox>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>면적</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{Math.floor(landArea).toLocaleString('ko-KR')}m²</Styled.LandTxt>
                        </Styled.LandBox>
                    </div>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>도로</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{roadSide}</Styled.LandTxt>
                        </Styled.LandBox>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>형상</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{landForm}</Styled.LandTxt>
                        </Styled.LandBox>
                    </div>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>지세</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{landHeight}</Styled.LandTxt>
                        </Styled.LandBox>
                        <Styled.LandBox>
                            <Styled.LandSubTitleTxt>공시지가</Styled.LandSubTitleTxt>
                            <Styled.LandTxt>{Math.floor(landPrice).toLocaleString('ko-KR')}원</Styled.LandTxt>
                        </Styled.LandBox>
                    </div>
                    <Styled.LandUsePlanBox>
                        <Styled.LandSubTitleTxt style={{width:"405px", marginTop:"15px", marginLeft:"2px"}}>토지 이용 계획</Styled.LandSubTitleTxt>
                        <Styled.LandTxt style={{width:"405px", height:"auto", marginLeft:"2px"}}>{landUses.replaceAll("/", ", ")}</Styled.LandTxt>
                    </Styled.LandUsePlanBox>
                    <Styled.DivLine/>
                    <Styled.LandTitleTxt>토지 실거래 내역</Styled.LandTitleTxt>
                    { dealChart.length === 0 ? 
                        <Styled.LandTxt style={{marginTop:"80px", width:"450px", fontSize:"15px", textAlign:"center"}}>주변 지역의 실거래 내역이 없습니다.</Styled.LandTxt>
                    : (
                        <Styled.DealList>
                            <thead>
                                <tr>
                                    <Styled.DealListTH style={{width:"80px"}}>거래일자</Styled.DealListTH>
                                    <Styled.DealListTH>거래유형</Styled.DealListTH>
                                    <Styled.DealListTH>거래금액 (원)</Styled.DealListTH>
                                    <Styled.DealListTH>거래면적 (㎥)</Styled.DealListTH>
                                    <Styled.DealListTH>단가</Styled.DealListTH>
                                </tr>
                            </thead>
                            <tbody>
                                {dealChart.map((deal, index) => {
                                    return (
                                        <tr key={index}>
                                            <Styled.DealListTD>{deal.deal_year + deal.deal_month.padStart(2, '0')}</Styled.DealListTD>
                                            <Styled.DealListTD>{deal.deal_type}</Styled.DealListTD>
                                            <Styled.DealListTD>{Math.floor(deal.land_real_price).toLocaleString('ko-KR')}원</Styled.DealListTD>
                                            <Styled.DealListTD>{Math.floor(deal.deal_area).toLocaleString('ko-KR')}m²</Styled.DealListTD>
                                            <Styled.DealListTD>{parseInt(deal.land_real_price / deal.deal_area).toLocaleString('ko-KR') + "원/m²"}</Styled.DealListTD>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Styled.DealList>
                    )}
                    <div style={{marginBottom:"150px"}}/>
                </Styled.Content>
            </Styled.Container>
            );
        } 
    }
}


export default LandDetails;